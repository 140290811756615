import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

function App() {

  //Attributes
  const FR_POLICY = process.env.REACT_APP_FR;
  const NL_POLICY = process.env.REACT_APP_NL;
  const EN_POLICY = process.env.REACT_APP_EN;

  //Render
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/fr' component={() => {
          window.location.href = `${FR_POLICY}`;
          return null;
        }} />
        <Route path='/nl' component={() => {
          window.location.href = `${NL_POLICY}`;
          return null;
        }} />
        <Route path='/en' component={() => {
          window.location.href = `${EN_POLICY}`;
          return null;
        }} />
        <Route path='/de' component={() => {
          window.location.href = `${EN_POLICY}`;
          return null;
        }} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
